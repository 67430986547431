export default {
  'delivery_notes.batch': 'Batch',
  'delivery_notes.total_weight': '總重量 (KG)',
  'delivery_notes.total_volume': '總體積(CBM)',
  'delivery_notes.total_cost': '總成本',
  'delivery_notes.packing_gift_card': '包裝 - 禮品卡',
  'delivery_notes.packing_gift_information': '包裝 - 禮品卡資料',
  'delivery_notes.packing_wrapping': '包裝 - 保護物料',
  'delivery_notes.packing_special_information': '包裝 - 特別訊息',
  'delivery_notes.packing_natural_beauty': '包裝 - Natural Beauty',
  'delivery_notes.reference_no': '參考編號',
  'delivery_notes.back_order': '後補單',
  'delivery_notes.cross_zone_pick': '跨區 - 揀貨',
  'delivery_notes.pending_non_amr_pick': '轉移到人工區 - 揀貨',
  'delivery_notes.warehouse': 'Warehouse',
  'delivery_notes.merchant_name': 'Merchant Name',
  'delivery_notes.status': 'Status',
  'delivery_notes.external_warehouse_status': 'External WMS Status',
  'delivery_notes.create': 'Create Outbound Order',
  'delivery_notes.create.fail': 'Fail to create outbound order',
  'delivery_notes.create.success': 'Delivery Note has been succesfully created',
  'delivery_notes.download_oms_label_failed': '下載出庫文件失敗',
  'delivery_notes.update.fail': 'Fail to update outbound order',
  'delivery_notes.update': 'Update',
  'delivery_notes.update.success': 'Delivery Note has been succesfully updated',
  'delivery_notes.form_item.title': 'Item Details',
  'delivery_notes.product': 'Product',
  'delivery_notes.search_product': 'Search Product',
  'delivery_notes.item.no': '編號',
  'delivery_notes.product_name': '產品名稱',
  'delivery_notes.pick_type': '已分配揀貨區',
  'delivery_notes.item.qty': 'Item Qty',
  'delivery_notes.item.order_qty': 'Order Qty',
  'delivery_notes.item.pre_pick_qty': '需揀貨數量',
  'delivery_notes.item.remain_pick_qty': '未揀貨數量',
  'delivery_notes.item.picked_qty': '已揀貨數量',
  'delivery_notes.item.declared_price': '報關價值',
  'delivery_notes.item.asn_code': '入庫單',
  'delivery_notes.item.currency': '貨幣',
  'delivery_notes.item.allocate_inv_product_type': '配貨的貨品類別',
  'delivery_notes.item.outbound_pick_items': '人工區 - 揀貨單詳情',
  'delivery_notes.release-selected-confirm': '你真的想傳送所選的出貨單到大貨區揀貨嗎?',
  'delivery_notes.push-external-confirm':
    'Are you sure you want to push all DN to external warehouse?',
  'delivery_notes.release_selected': '傳送所選的出貨單',
  'delivery_notes.allocate_order': '正在配貨中...',
  'delivery_notes.allocate_order.fail': '配貨失敗!',
  'delivery_notes.print_picklist.fail': '下載揀貨任務失敗',
  'delivery_notes.print_picklist.all': '下載全部還沒有列印的揀貨任務',
  'delivery_notes.confirm_to_new': 'Confirm To New',
  'delivery_notes.confirm-to-new-confirm':
    'Are you sure you want to confirm this {document_no} to new order?',
  'delivery_notes.release_to_picklist': 'Allocate To Picklist',
  'delivery_notes.release-confirm':
    'Are you sure you want to release this {document_no} to picking list?',
  'delivery_notes.push-to-warehouse': 'Push To External WMS',
  'delivery_notes.push-warehouse-confirm':
    'Are you sure you want to push this {document_no} to External WMS (Upload DN to FTP)?',
  'delivery_notes.confirm_picked': 'Confirm Picked',
  'delivery_notes.confirm-picked-confirm':
    'Are you sure you want to confirm this {document_no} picked?',
  'delivery_notes.dispatch_shipping': 'Dispatch Shipping',
  'delivery_notes.dispatch-shipping-confirm':
    'Are you sure you want to dispatch this {document_no} shpping?',
  'delivery_notes.scan_picklist_code': '掃描揀貨號',
  'delivery_notes.product_sku': 'Product SKU',
  'delivery_notes.serial_number': 'Serial Number',
  'delivery_notes.serial_numbers_list': '{product_name} Serial Numbers',
  'delivery_notes.show_serial_number': 'Show Serial Numbers',
  'delivery_notes.scanned_qty': '已掃描數量',
  'delivery_notes.pack_handling': '正在包裝的出貨單號',
  'delivery_notes.pick_handling': '正在揀貨的出貨單號',
  'delivery_notes.picked_success': '這個 {scanned_code} 已掃描了 {qty} 件',
  'delivery_notes.search': 'Search',
  'delivery_notes.warehouse_bin': 'Warehouse Bin',
  'delivery_notes.allocated_asn_or_rma': '預分配ASN / RMA',
  'delivery_notes.user_picked.load_all_confirm':
    '系統之前自動儲存了已掃描過的產品, 你想載入之前的數據嗎?',
  'delivery_notes.save_current_scanned_dn': 'Save Current Scanned List',
  'delivery_notes.scan_bar_codes': '產品條碼',
  'delivery_notes.ordered_qty': '數量',
  'delivery_notes.scan_tracking_no': 'Scan Tracking No.',
  'delivery_notes.scan_delivery_note': '掃描出貨單',
  'delivery_notes.scan_container_code': 'Scan Container Note',
  'delivery_notes.scan_amr_container_code': '掃描週轉箱',
  'delivery_notes.booking_no': 'BL No.',
  'delivery_notes.platform_code': '電商平台',
  'delivery_notes.wms_carrier_code': '物流商編碼',
  'delivery_notes.shipped_at': 'Shipped At',
  'delivery_notes.truck_detail': 'Truck Details',
  'delivery_notes.courier_service_code': 'SR 服務號',
  'delivery_notes.tracking_no': '追蹤單號',
  'delivery_notes.carton_barcode': '紙箱條碼',
  'delivery_notes.other_carton': '其他紙箱',
  'delivery_notes.reset-scan-carton-barcode': '重設掃描紙箱條碼',
  'delivery_notes.sbn_require_packing_carton_no': 'SBN建議箱號',
  'delivery_notes.not_found_carton_barcode': '紙箱條碼 - {carton_barcode} 未找到. 請查看.',
  'delivery_notes.not_match_require_packing_carton_no': '與建議箱號不符',
  'delivery_notes.packed-variance-require-carton-confirm': '{document_no} 的包裝箱尺寸不符合 SBN 的要求，箱號：#{require_packing_carton_no}。請確認這是正確的包裝？',
  'delivery_notes.priority': '優先值',
  'delivery_notes.packed_dimension': '包裝後三邊碼 (CM)',
  'delivery_notes.packed_weight': '包裝後 - 重量',
  'delivery_notes.packed_length': '包裝後 - 長',
  'delivery_notes.packed_width': '包裝後 - 闊',
  'delivery_notes.packed_height': '包裝後 - 高',
  'delivery_notes.item.amount': '價值',
  'delivery_notes.item.packing_spec': 'Packed Spec',
  'delivery_notes.wms_container_code': '週轉箱號',
  'delivery_notes.shipped.success': '出貨單已標記為發貨',
  'delivery_notes.update_tracking.fail': '更新追蹤資料失敗',
  'delivery_notes.download.documents': 'Download all required documents',
  'delivery_notes.picklist_no': 'Picklist No.',
  'delivery_notes.amr_picked_items': 'AMR {document_no} Picked Items',
  'delivery_notes.regenerate-picklist-confirm':
    'Are you sure want to regenerate all available picklist No.',
  'delivery_notes.scan_picklist.no_data': '這個揀貨號 - {picklist_no} 找不到相閞的揀貨訊息',
  'delivery_notes.scan_delivery_note.no_data': '{delivery_note_code} 驗證失敗，沒有可打包的資料',
  'delivery_notes.item.scan_method': '掃描方式',
  'delivery_notes.disptach_update_tracking': '更新 {document_no} 追蹤訊息',
  'delivery_notes.pack_update_tracking': '更新 {document_no} 包裝後訊息',
  'delivery_notes.bar_code': '產品條碼',
  'delivery_notes.upload_packed_report': 'Upload Packed Report',
  'delivery_notes.upload_dispatched_report': 'Upload Dispatched Report',
  'delivery_notes.upload_packed_items': 'Packed Report Items',
  'delivery_notes.upload_dispatched_items': 'Dispatched Report Items',
  'delivery_notes.download-delivery-notes': 'Download DN',
  'delivery_notes.download-summary': 'Download Summary',
  'delivery_notes.upload_file_name': 'Upload File Name',
  'delivery_notes.upload_dispatch_title': 'Upload Dispatch Report Items',
  'delivery_notes.confirm-dispatch': 'Pending Confirm Upload Dispatch Report To Ship',
  'delivery_notes.show-dispatch-items': 'Show Dispatch Report Items',
  'delivery_notes.dispatch.hold': 'Hold the DN to dispatch',
  'delivery_notes.dispatch.recovery': 'Recovery the DN to dispatch',
  'delivery_notes.download_packlist': '下載打包列表',
  'delivery_notes.print_all_new_picklist': '下載並列印全部還沒有列印的 Picklist',
  'delivery_notes.download_new_packlist_items': '下載最新揀貨列表(Excel)',
  'delivery_notes.packlist.total': '共',
  'delivery_notes.packlist.total.dn': '出貨單',
  'delivery_notes.scan_dn_or_tracking': 'Scan DN or Tracking No.',
  'delivery_notes.packed-at': 'Packed At',
  'delivery_notes.scan_dn_or_tracking_no': '出貨單號或追蹤號',
  'delivery_notes.scan_code_or_tracking_no': 'Scan Console Code / Tracking No.',
  'delivery_notes.ean_or_upc_code': 'EAN / UPC / (SKU)編碼',
  'delivery_notes.download_pack_label_alert_title': '包裝 {document_no} 所需要的文件',
  'delivery_notes.pack.scan_more_fail':
    '訂單 {document_no} EAN / UPC / (SKU) {code} 只允許掃描 {scanned_qty} 件貨, 請查看',
  'delivery_notes.no_amr.picked_success_title': '揀貨成功，請查看',
  'delivery_notes.no_amr.total_picked_qty': '共有: {picked_qty} 件',
  'delivery_notes.pack_box_number.title': '{carton_no}號紙箱',

  'delivery_notes.outbound_pack_boxes.list': '倉庫預設紙箱',
  'wms_picking_container.wms_container_code': 'WMS Container Code',
  'wms_picking_container.show_container_detail': '顯示WMS揀貨箱詳情',
  'wms_picking_container.pallet_code': 'Pallet Code',
  'wms_picking_container.sku_type_amount': 'SKU 款式',
  'wms_picking_container.sku_amount': '數量',
  'wms_picking_container.wms_provider_code': '供應來源',
  'wms_picking_container.picker': '處理者',
  'wms_picking_container.item.outbound_type': '出庫類型',
  'wms_picking_container.item.transfer_order_code': 'Transfer 單號',
  'wms_picking_container.item.asn_code': 'ASN 號碼',
  'wms_picking_container.item.amount': '揀貨數量',
  'wms_picking_container.item.picking_product_type': '貨品類別定義',
  'wms_picking_container.item.country_code_of_origin': '產品原廠地',
  'wms_picking_container.item.container_amount': '揀貨箱產品數',
  'wms_picking_container.item.expiration_date': '過期日期',
  'wms_picking_container.item.is_last_container': '是否為出貨單最後一箱',
  'wms_picking_container.item.finish_time': '揀貨完成時間',
  'wms_picking_container.pick-container-item-title':
    'WMS Container {wms_container_code} Picked Items',
  'wms_picking_container.release-selected': '釋放已選AMR容器',
  'wms_picking_container.release-selected-confirm':
    'Are you sure you want to release selected AMR container?',
  'wms_picking_container.release-confirm':
    'Are you sure you want to release AMR container {container_code}?',
  'wms_picking_container.retry-confirm':
    'Are you sure you want to re-try AMR container {container_code}?',
  'delivery_notes.delivery_note_code': '出貨單號',
  'delivery_notes.require_courier_invoice': '需要快遞發票',
  'delivery_notes.upload_waybill_title': '上傳 {document_no} Waybill 檔案',
  'delivery_notes.upload_waybill': '上傳 Waybill',
  'delivery_notes.download_waybill': '下載 Waybill',
  'delivery_notes.upload_invoice_title': '上傳 {document_no} 發票檔案',
  'delivery_notes.upload_invoice': '上傳發票',
  'delivery_notes.download_invoice': '下載發票',
  'delivery_notes.auto-release-dn-title': '自動配貨新出貨單',
  'delivery_notes.cancel-confirm': 'Are you sure to cancel this {document_no} delivery note?',
  'delivery_notes.return': 'Return',
  'delivery_notes.return-confirm': 'Are you sure to return this {document_no} delivery note?',
  'delivery_notes.export_to_excel': 'Export your data to excel',
  'delivery_notes.download-selected-confrim': 'Are you sure to download selected outbound orders?',
  'delivery_notes.download_selected': 'Download Selected',
  'delivery_notes.download': 'Download',
  'delivery_notes.show_tracking': 'Show Tracking',
  'delivery_notes.asn': 'ASN',
  'delivery_notes.delivery_company': '公司名稱',
  'delivery_notes.delivery_name': '聯絡人',
  'delivery_notes.delivery_email': '聯絡電郵',
  'delivery_notes.address': '地址',
  'delivery_notes.address1': '地址1',
  'delivery_notes.address2': '地址2',
  'delivery_notes.country': '國家',
  'delivery_notes.city': '城市',
  'delivery_notes.state': '省',
  'delivery_notes.postal': '郵政號',
  'delivery_notes.phone': '電話',
  'delivery_notes.require_transfer': '需要補貨到 AMR',
  'delivery_notes.require_cancel': '需要取消訂單',
  'delivery_notes.item.request_asn_code': '來自 ASN / RMA',
  'outbound_consoles.outbound_console_code': 'Console Code',
  'outbound_consoles.scan_dn_or_tracking': 'Scan DN or Tracking No.',
  'outbound_consoles.scan_container_box_code': 'Scan Container Box(Outbound)',
  'outbound_consoles.scan_container_code.success': 'Scan (Outbound) container box code Succesfully',
  'outbound_consoles.update_packed_weight': 'Update {document_no} Packed Weight',
  'outbound_consoles.scan_code_or_tracking': 'Scan Console Code or Tracking No.',
  'outbound.release-picking-title': '創建全部 DN 揀貨任務',

  'outbound.document.name.long.name': '出貨單',
  'outbound.index.tab.dn': '出貨單 (All-DN)',
  'outbound.index.tab.dn.title': '出貨單 (All-DN)',
  'outbound.index.tab.new-order': '新出貨單(All)',
  'outbound.index.tab.new-order.title': '新出貨單(All)',
  'outbound.index.tab.amr-pick-order': 'AMR區出貨單',
  'outbound.index.tab.amr-pick-order.title': 'AMR區出貨單 ',
  'outbound.index.tab.no-amr-pick-order': '人工區出貨單',
  'outbound.index.tab.no-amr-pick-order.title': '人工區出貨單',
  'outbound.index.tab.upload-dispatch': '上傳出貨資料',
  'outbound.index.tab.upload-dispatch.title': '上傳出貨資料',
  'outbound.index.tab.transfer-pending-outbound': '等待下架單完成',
  'outbound.index.tab.transfer-pending-outbound.title':
    '等待下架單完成後，系統才允許接收的新出庫單',
  'outbound.amr.index.tab.pack-list': 'AMR 打包列表 (列印)',
  'outbound.amr.index.tab.pack-list.title': 'AMR 打包列表 (列印)',
  'outbound.amr.index.tab.pack': 'AMR區 打包',
  'outbound.amr.index.tab.pack.title': 'AMR區 打包',
  'outbound.amr.index.tab.dispatch': '訂單 - 出貨',
  'outbound.amr.index.tab.dispatch.title': '訂單 - 出貨',
  'outbound.amr.index.tab.release-container': 'AMR揀貨箱(回傳)',
  'outbound.amr.index.tab.release-container.title': 'AMR揀貨箱(回傳)',
  'outbound.pick.tab.amr-picklist': 'AMR區 揀貨任務',
  'outbound.pick.tab.amr-picklist.title': 'AMR區 揀貨任務',
  'outbound.pick.tab.no-amr-picklist': '人工區 揀貨任務',
  'outbound.pick.tab.no-amr-picklist.title': '人工區 揀貨任務',
  'outbound.pick.tab.no-amr-pick': '人工區 揀貨',
  'outbound.pick.tab.no-amr-pick.title': '人工區 揀貨',
  'outbound.pick.tab.no-amr-container-pick': '人工區(掃箱號) 揀貨',
  'outbound.pick.tab.no-amr-container-pick.title': '人工區(掃箱號) 揀貨',
  'outbound.pack.tab.pack-order': '打包',
  'outbound.pack.tab.pack-order.title': '打包',
  'outbound.pack.tab.no-amr-pick-pack': '人工區 揀貨&打包',
  'outbound.pack.tab.no-amr-pick-pack.title': '人工區 揀貨&打包',
  'outbound.pack.tab.scan-to-pack': '掃描驗證產品',
  'outbound.pack.tab.scan-to-pack.title': '掃描驗證產品',
  'outbound.pack.tab.pack-waybill': '打印包裝Label',
  'outbound.pack.tab.pack-waybill.title': '打印包裝Label',
  'outbound.pack.tab.reprint': '重新打印',
  'outbound.dispatch.tab.amr-dispatch': '訂單 - 出貨',
  'outbound.dispatch.dispatch': '訂單 - 出貨',
  'outbound.dispatch.tab.amr-dispatch.title': '訂單 - 出貨',
  'outbound.dispatch.tab.dispatch.title': '訂單 - 出貨',
  'outbound.dispatch.tab.dispatch_v2.title': '訂單 - 出貨 (新版)',
  'outbound.dispatch.tab.manifest.title': '載貨清單',
  'outbound.dispatch.tab.no-amr-dispatch': '人工區 出貨',
  'outbound.dispatch.tab.no-amr-dispatch.title': '人工區 出貨',
  'outbound.pick.scan.ean-or-sku': '{scanned_code} 不是這個產品設定可掃描的條碼',
  'outbound.pick.scan.ean-or-sku.finish_scanning': '{scanned_code} 已完成掃描，不可重複掃描',
  'outbound.return.tab.return': '回單到人工區',
  'outbound.return.tab.return.title': '回單到人工區',
  'outbound.return.tab.put-away': '重新上架',
  'outbound.return.tab.put-away.title': '重新上架',
  'outbound.return.tab.return-orders': '回貨單',
  'outbound.return.tab.return-orders.title': '回貨單',
  'outbound.return.tab.new-item': '回貨列表',
  'outbound.return.tab.new-item.title': '回貨列表',
  'outbound.release.message': '系統自動配貨新出貨單中 ...',
  'outbound.release.fail.message': '自動配貨新出貨單失敗!',
  'outbound.release.all.message': '自動配貨新出貨單',
  'outbound.release.all.button': '自動配貨新出貨單',
  'outbound.release.selected.button': '為所選的出貨單配貨',
  'outbound.release.selected.dn.confirmation': '你確定為所選的出貨單配貨嗎?',
  'outbound.amr.packlist.download': '沒有打包列表可以下載',
  'outbound.amr.packlist.single.dn.download.fail': '下載出貨單失敗!',
  'outbound.amr.pack.item.table.title': '需要包裝旳產品',
  'outbound.amr.pack.confirm.pack.fail': '確認這個包裝失敗',
  'outbound.amr.pack.download.courier-label.fail': '下載快遞商的標籤失敗',
  'outbound.amr.pack.download.waybill.fail': '下載運輸服商的標籤失敗',
  'outbound.amr.pack.download.client-dn.fail': '下載客戶的 DN 失敗',
  'outbound.amr.pack.download.client-dg-form.fail': '下載危險品表單失敗',
  'outbound.amr.pack.download.client-dg-carton.fail': '下載危險品箱標籤失敗',
  'outbound.amr.pack.download.client-invoice.fail': '下載發票失敗',
  'outbound.amr.pack.download.client-courier-invoice.fail': '下載運輸服務商的發票失敗',
  'outbound.amr.pack.download.sr-dn.fail': '下載 DN 失敗',
  'outbound.amr.pack.download.fail': '下載檔案失敗',
  'outbound.amr.pack.reprint_type': '重新打印文件類型',
  'outbound.pack.tab.reprint.title': '重新打印文件類型',
  'outbound.pack.tab.reinput-weight': '重新磅度',
  'outbound.pack.tab.reinput-weight.title': '重新磅度',

  'outbound.amr.pack.dispatch.duplicate.scan': '{scanned_dn} 已掃描過了',
  'outbound.amr.pack.dispatch.require.scan.tracking':
    '請掃描追蹤號，不要掃描這個 DN no. - {delivery_note_code}',
  'outbound.amr.gtn.container': '週轉箱',
  'outbound.packed.reprint': 'AMR 重新列印',
  'outbound.reprint-amr-outbound-title': '重新列印 AMR 出貨的文件',
  'outbound.amr.packed.reprint.message': '重新列印中...',
  'outbound.amr.packed.reprint.fail.message': '重新列印出貨用的文件失敗',
  'outbound.picking.dn_status': '出貨單狀態',
  'outbound.dispatch.load_time': '出貨單載入時間: {when}',
  'outbound.dispatch.number_of_packed_order_before_cutoff': '截單前該出貨總單量',
  'outbound.dispatch.number_of_packed_orders': '已包裝件數',
  'outbound.dispatch.number_of_packed_order_dispatched': '已出貨單量',
  'outbound.dispatch.cutoff_time': '{courier_name} 的截單時間',
  'outbound.dispatch.time_remaining_before_cutoff': '距離截單時間尚餘',
  'outbound.dispatch.awaiting_dispatch': '未出貨',
  'outbound.dispatch.dispatched': '已出貨',
  'outbound.dispatch.dispatch_date': '出貨日期',
  'outbound.dispatch.problem_dn': '有問題的單號',
  'outbound.dispatch.extra_dn': '載入時沒有 / 或截單後的單號 - 已出貨',
  'outbound.dispatch.passed_cutoff_dn': '過了截單才包裝 - 未出貨',
  'outbound.dispatch.button.change_pallet_id': '轉板號',
  'outbound.dispatch.shipped_at_message': '出貨於: {shipped_at}',
  'outbound.dispatch.requested_cancel_at_message': '請求取消於: {request_cancel_at}',
  'outbound.dispatch.manifest.no_basic_info': '基本設定出了問題，請聯系 IT support',
  'outbound.dispatch.plesae-select-carriers': '請擇選至少一個快遞商',

  'delivery_note.pick-work-item-title': '揀貨任務號 {picklist_task_no} 詳情',
  'delivery_notes.picking_task_no': '揀貨任務號',
  'delivery_notes.show_picking_task_detail': '顯示揀貨任務詳情',
  'delivery_notes.wave_order_code': '波次單號',
  'delivery_notes.wave_type': '波次類型',
  'delivery_notes.wave_structure': '波次結構',
  'delivery_notes.is_printed': '已經打印',
  'delivery_notes.dispatched_pallet_id': '板號',
  'delivery_notes.plan_number_of_items': '計劃揀貨種類',
  'delivery_notes.plan_number_of_sku_qty': '計劃揀貨數量',
  'delivery_notes.print-picklist-selected-confirm': '真的要下載所選取揀貨任務嗎？',
  'delivery_notes.print_picklist_selected': '列印所選的揀貨單',
  'delivery_notes.select_warehouse_first': '請先選擇倉庫',
  'delivery_notes.picklist.item': '揀貨的產品',
  'delivery_notes.print_picklist.title': '打印揀貨任務',
  'delivery_notes.print_picklist.button': '打印所有揀貨任務(剛創建)',
  'delivery_notes.placeholder-allcation-zone-property':
    '指定配貨區域(AMR 或 Non-AMR), 留空則不指定',
  'delivery_notes.step.created_at': '接單',
  'delivery_notes.step.released_at': '釋放',
  'delivery_notes.step.non_amr_picked_at': '揀貨',
  'delivery_notes.step.amr_picked_at': 'AMR 揀貨',
  'delivery_notes.step.packed_at': '包裝',
  'delivery_notes.step.shipped_at': '出貨',
  'delivery_notes.step.request_cancel_at': '要求取消',
  'delivery_notes.step.cancelled_at': '取消',
  'delivery_notes.step.handled_by': '操作員: {name} ',
  'delivery_notes.return.item': '回貨詳情',
  'delivery_notes.outbound_return_code': '回貨單號',
  'delivery_notes.print-return-order-selected-confirm': '真的要下載所選取回貨單嗎？',
  'delivery_notes.print_return_order_selected': '列印所選的回貨單',
  'delivery_notes.show_outbound_return_detail': '顯示回貨單詳情',
  'delivery_note.return-order-item-title': '回貨單號 {return_order_code} 詳情',
  'delivery_notes.order_total_qty': '訂單總件數',
  'delivery_notes.button.confirm': '確認',
  'outbound_return_item.from_zone_property': '原區域類型',
  'outbound_return_item.from_bin_property': '原架位類型',
  'outbound_return_item.from_container_box_code': '原箱號',
  'outbound_return_item.to_zone_property': '新上架區域類型',
  'outbound_return_item.to_bin_property': '新架位類型',
  'outbound_return_item.to_container_box_code': '新箱號',
  'outbound_return_item.to_warehouse_bin': '新架位',
  'outbound_return_item.product_qty': '回貨數量',
  'outbound_return_item.put_away_qty': '重新上架數量',
  'outbound_return_item.pending_put_away_qty': '待上架數量',
  'delivery_notes.update.warehouse_info': '更新單訂於倉庫的資料',
  'delivery_notes.details.items': '貨物詳情',
  'delivery_notes.details.packing_list': '包裝詳情',
  'delivery_notes.packed_at': '包裝日期及時間',
  'delivery_notes.packing_carton_no': '包裝箱號',
};
